<template>
  <v-card class="my-4">
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="12">
          <h1 class="primary--text">Alerte du {{ alert.formatted.date }}</h1>
          <p class="mt-2 font-weight-regular font-italic">
            {{ alert.rule.name }}
            <RulePopup v-if="alert.rule_id" :ruleId="alert.rule_id" />
          </p>
        </v-col>

        <v-col cols="12">
          <h2 class="accent--text">Résumé</h2>

          <AlertAnalysis v-model="alert" />

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>{{ keyLabel }}</th>
                  <th class="text-center">{{ valueLabel }}</th>
                  <th class="text-center">Risque Financier</th>
                  <th class="text-center">Période d'analyse</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <v-chip color="accent">
                      <v-icon>mdi-{{ icons[alert.kind] }}</v-icon>

                      <v-divider class="mx-2" inset vertical></v-divider>
                      {{ alert.key }}</v-chip
                    >
                  </td>
                  <td class="text-center">
                    {{ alert.formatted.triggered_value }}
                    <v-tooltip
                      v-if="alert.triggered_value_threshold"
                      bottom
                      color="rgba(0, 0, 0, 0.9)"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon right v-bind="attrs" v-on="on">
                          mdi-information
                        </v-icon>
                      </template>
                      <span>Risque max accepté :</span>
                      {{ alert.formatted.triggered_value_threshold }}
                    </v-tooltip>
                  </td>
                  <td class="text-center">
                    {{ alert.formatted.triggered_financial_risk }}
                  </td>
                  <td class="text-center">
                    {{ alert.triggered_duration }} {{ $t("day") }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { getKeyLabel } from "@/services/rule";

export default {
  props: {
    alert: {
      type: Object,
      required: true,
    },
  },
  components: {
    RulePopup: () => import("@/components/Rules/RulePopup"),
    AlertAnalysis: () => import("@/components/AlertDetails/AlertAnalysis"),
  },
  computed: {
    ...mapState({
      icons: (state) => state.alerts.alertsIcons,
    }),

    keyLabel() {
      return getKeyLabel("key", { rule: this.alert.rule }) || this.$t("origin");
    },

    valueLabel() {
      return getKeyLabel("value", { rule: this.alert.rule }) || this.$t("value");
    },
  },
};
</script>
